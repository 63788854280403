import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import PrimaryBox from 'components/Advanced_User/IOBroker_on_Raspberry_Pi/PrimaryBox';
import NavButtons from 'components/Advanced_User/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "ioBroker Installation",
  "path": "/Advanced_User/IOBroker_on_Raspberry_Pi/IOBroker_Installation/",
  "dateChanged": "2018-09-20",
  "author": "Mike Polinowski",
  "excerpt": "ioBroker is an integration platform for the Internet of Things, focused on Building Automation, Smart Metering, Ambient Assisted Living, Process Automation, Visualization and Data Logging. Control your INSTAR IP camera with your Raspberry Pi. Compatible with the camera models IN-6001 HD, IN-6012 HD, IN-6014 HD, IN-5905 HD, IN-5907 HD, IN-7011 HD, IN-8003 Full HD, IN-8015 Full HD, IN-9008 Full HD, IN-9020 Full HD",
  "image": "../AU_SearchThumb_ioBroker.png",
  "social": "/images/Search/AU_SearchThumb_ioBroker.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_Advanced_ioBroker_white.webp",
  "chapter": "Advanced User",
  "category": "smarthome",
  "type": "ioBroker"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='ioBroker Installation' dateChanged='2018-09-20' author='Mike Polinowski' tag='INSTAR IP Camera' description='ioBroker is an integration platform for the Internet of Things, focused on Building Automation, Smart Metering, Ambient Assisted Living, Process Automation, Visualization and Data Logging. Control your INSTAR IP camera with your Raspberry Pi. Compatible with the camera models IN-6001 HD, IN-6012 HD, IN-6014 HD, IN-5905 HD, IN-5907 HD, IN-7011 HD, IN-8003 Full HD, IN-8015 Full HD, IN-9008 Full HD, IN-9020 Full HD' image='/images/Search/AU_SearchThumb_ioBroker.png' twitter='/images/Search/AU_SearchThumb_ioBroker.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Erweitert/IOBroker_auf_Raspberry_Pi/IOBroker_Installation/' locationFR='/fr/Advanced_User/IOBroker_on_Raspberry_Pi/IOBroker_Installation/' crumbLabel="ioBroker" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "iobroker-installation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#iobroker-installation",
        "aria-label": "iobroker installation permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ioBroker Installation`}</h1>
    {/* TOC */}
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#installation-of-iobroker"
        }}>{`Installation of ioBroker`}</a></li>
    </ul>
    {/* /TOC */}
    <EuiSpacer mdxType="EuiSpacer" />
    <PrimaryBox mdxType="PrimaryBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`Create a directory and install ioBroker using the node package manager:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`npm`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`install`}</span>{` iobroker --unsafe-perm`}</code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`mkdir`}</span>{` /opt/iobroker
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`chmod`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`777`}</span>{` /opt/iobroker
`}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`cd`}</span>{` /opt/iobroker
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`npm`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`install`}</span>{` iobroker --unsafe-perm`}</code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/14592fcb2bd83501f7ad0fe87b12bec0/e9beb/IOBroker_19.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "36.95652173913043%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABZUlEQVQY01XOa0+CUADG8SNegmy5tlZOK3VqxeEqcA5XAYPDxRsoiubs+3+N5tbc2p63vz1/gMuzsj3ym2Myz+e2nzhfLpp62CG2F1puaHsz7PATs4XslmoxkkFxqMphMJIGJAO4PBuHn89lIW0Oo3je9UnHi17csOsGQ5IOgrjtzJ7tr2EQ9/3wUXfbTtiZEloy5PwIJtvvcZILWSnkpeyTANldxaIlkxZQU9IfsH2vWrSI7xSzpRgNAVMQURy6PIdroBanPlkNomyYZKxPkOX1DK8u4CrUbgTUlC+MFnFTMZsTo87jCtQoeMW708diw2elmO1HJB3G6ThKO/aspTq3os4IiBFRQ8ANUa9yqArRPzzZHgdkDVd7blWw0WIcJO9h2vejJ3PGSEYNaoyk13gEWK3CajUOURBVrtncav8aLNjlDi6LURi/eUHXIX0/6vkxI5uAVev8BQBWA6xGQa1y2R/+BYqtUQXbqQzfAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/14592fcb2bd83501f7ad0fe87b12bec0/e4706/IOBroker_19.avif 230w", "/en/static/14592fcb2bd83501f7ad0fe87b12bec0/d1af7/IOBroker_19.avif 460w", "/en/static/14592fcb2bd83501f7ad0fe87b12bec0/b6582/IOBroker_19.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/14592fcb2bd83501f7ad0fe87b12bec0/a0b58/IOBroker_19.webp 230w", "/en/static/14592fcb2bd83501f7ad0fe87b12bec0/bc10c/IOBroker_19.webp 460w", "/en/static/14592fcb2bd83501f7ad0fe87b12bec0/87ca7/IOBroker_19.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/14592fcb2bd83501f7ad0fe87b12bec0/81c8e/IOBroker_19.png 230w", "/en/static/14592fcb2bd83501f7ad0fe87b12bec0/08a84/IOBroker_19.png 460w", "/en/static/14592fcb2bd83501f7ad0fe87b12bec0/e9beb/IOBroker_19.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/14592fcb2bd83501f7ad0fe87b12bec0/e9beb/IOBroker_19.png",
              "alt": "ioBroker Installation",
              "title": "ioBroker Installation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/b25271ab41afa73d31dc2abd60114a9c/e9beb/IOBroker_20.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "8.695652173913043%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAACCAIAAADXZGvcAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAa0lEQVQI12MwDI62iIg3Co4yCI02t3NLcvSMd/YItXL0NrUNMrHm1zBi0jbh0DJh1jJl1TFHRiw65gzq3sG6/mHq3sE6vqFSdg4qDpZiJnaChpbChlb8BpYMmsaMmiaMmiYMmiaMGiDEgIQAFWwWZQowtEsAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b25271ab41afa73d31dc2abd60114a9c/e4706/IOBroker_20.avif 230w", "/en/static/b25271ab41afa73d31dc2abd60114a9c/d1af7/IOBroker_20.avif 460w", "/en/static/b25271ab41afa73d31dc2abd60114a9c/b6582/IOBroker_20.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/b25271ab41afa73d31dc2abd60114a9c/a0b58/IOBroker_20.webp 230w", "/en/static/b25271ab41afa73d31dc2abd60114a9c/bc10c/IOBroker_20.webp 460w", "/en/static/b25271ab41afa73d31dc2abd60114a9c/87ca7/IOBroker_20.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b25271ab41afa73d31dc2abd60114a9c/81c8e/IOBroker_20.png 230w", "/en/static/b25271ab41afa73d31dc2abd60114a9c/08a84/IOBroker_20.png 460w", "/en/static/b25271ab41afa73d31dc2abd60114a9c/e9beb/IOBroker_20.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/b25271ab41afa73d31dc2abd60114a9c/e9beb/IOBroker_20.png",
              "alt": "ioBroker Installation",
              "title": "ioBroker Installation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Check if ioBroker is up with your webbrowser: `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`http://<IP Address of your Pi>:8081`}</code></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/133cd16403465fc91c3fa76793a4ee16/e9beb/IOBroker_21.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.52173913043479%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABtklEQVQoz42OTUsbURSG58f0Lwi6qRs/QsCFDfWD0nbTWu2YTFIVwUUbIrXYhY6LgqJBhbRoFVcqdOVE8aNUcOGioS1lLDP33rnnXJPJRFOqmUpzS/AD1Idn8Z7Fy3uUQCBwL3R/8FV8MJHQYj1dandnd7RDjT5TNTUcUcORiBbToi+kES3W09vX/uBxTfXdmmCj0tD6dPrj8st3S/FV8nbDG067czsHxv6vlT17yHATa/mh9cKbjaOKw5vF+CceTmXaklvKnaq61xMLTX3jLbNWc8oNpfDRe/J8gT38wBqT2JDE+qkL1k1icOYwOFtoX6RKbTDU3NH/ZGBk0jCntkVyG/U0jqbFiPEv6AboBoyl8by6AaNrQl//qZg2Jzyb8445tYVD8ofgZSti+cRCTpw3n8WTgrv346syvQMzu+78fvGAguM4DgcHkDDHAeRSFJdkgLms+/lbRvGOir//nJ6cliillmUBACKSMogoT3ERQPRy7pfvGeVvGd/3CSGWZWGZSr6h7Pv/y5RSxpgQQpYBQGZxhcvLpVIJABhjcplzDgCc81uVfd+3bds0TfmnfFiOX1M+A0CwEkxMNSw+AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/133cd16403465fc91c3fa76793a4ee16/e4706/IOBroker_21.avif 230w", "/en/static/133cd16403465fc91c3fa76793a4ee16/d1af7/IOBroker_21.avif 460w", "/en/static/133cd16403465fc91c3fa76793a4ee16/b6582/IOBroker_21.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/133cd16403465fc91c3fa76793a4ee16/a0b58/IOBroker_21.webp 230w", "/en/static/133cd16403465fc91c3fa76793a4ee16/bc10c/IOBroker_21.webp 460w", "/en/static/133cd16403465fc91c3fa76793a4ee16/87ca7/IOBroker_21.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/133cd16403465fc91c3fa76793a4ee16/81c8e/IOBroker_21.png 230w", "/en/static/133cd16403465fc91c3fa76793a4ee16/08a84/IOBroker_21.png 460w", "/en/static/133cd16403465fc91c3fa76793a4ee16/e9beb/IOBroker_21.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/133cd16403465fc91c3fa76793a4ee16/e9beb/IOBroker_21.png",
              "alt": "ioBroker Installation",
              "title": "ioBroker Installation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      